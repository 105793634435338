<template>
  <div class="position-absolute">
    <b-modal
      id="personalization-editor-modal"
      ref="personalization-editor-modal"
      centered
      size="lg"
      footer-class="flex-nowrap"
      no-close-on-backdrop
    >
      <template #modal-header>
        <h3>{{ $t('settings.page.context.title') }}</h3>
        <b-dropdown
          class="context-menu"
          toggle-class="dot-button"
          no-caret
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="copySettings">
            {{ $t('dropdowns.copySettings') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="settingsCopied"
            @click="pasteSettings"
          >
            {{ $t('dropdowns.pasteSettings') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #default>
        <page-settings-context
          v-if="personalization"
          class="mt-5"
          :context-data="context"
          :personalization-values="personalizationValues"
          :all-actions="allActions"
          :source-options="sourceOptions"
          @context-create="createContext"
          @context-update="updateContext"
          @context-element-update="updateContextElement"
          @context-copy="copyContext"
          @remove-element="removeElement"
          @remove-context="removeContext"
        ></page-settings-context>
      </template>

      <template #modal-footer>
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="cancelModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="saveContext"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'ModalPersonalizationEditor',
  components: {
    PageSettingsContext: () => import('@/components/settings/page/PageSettingsContext.vue'),
  },
  props: {
    personalization: {
      type: Array,
      required: false,
      default: null,
    },
    contextData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      context: [],
      disableOkButton: true,
      source: 'personalization',
      action: 'showElement',
      selectedOrIndex: undefined,
      settingsCopied: false,
      showNewContextModal: false,
      showContextItemModal: false,
      selectedAction: null,
      selectedSource: null,
      selectedAndIndex: null,
      isElementUpdate: false,
      selectedLeftValue: null,
      selectedOperator: null,
      selectedRightValue: null,
      selectedActionIndex: null,
    };
  },
  created() {
    // Make a deep copy of the contextData to avoid mutating props directly
    this.context = cloneDeep(this.contextData) || [];
    // Check if there are saved settings in localStorage
    if (localStorage.getItem('context_settings')) {
      this.settingsCopied = true;
    }
  },
  mounted() {
    this.$refs['personalization-editor-modal'].show();
  },
  computed: {
    computedDisableButton() {
      if (this.context && this.context.length > 0) {
        return false;
      }
      return this.disableOkButton;
    },
    personalizationValues() {
      // Transform personalization data to the format expected by PageSettingsContext
      return this.personalization.map((set) => ({
        name: set.name,
        setId: set.personalized_variable_set_id,
        values: set.schema,
      }));
    },
    availableCombinations() {
      return this.possibleSourcesActionCombinations.filter((combination) => {
        const { action, source } = combination;
        return !this.context.some(
          (context) => context.action === action && context.source === source,
        );
      });
    },
    possibleSourcesActionCombinations() {
      const combinations = [];
      this.allActions.forEach((action) => {
        this.sourceOptions.forEach((source) => {
          if (!source.disabled && !action.disabled) {
            combinations.push({
              action: action.value,
              source: source.value,
            });
          }
        });
      });
      return combinations;
    },
    sourceOptions() {
      return [
        {
          value: 'personalization',
          translationKey: 'personalization',
          disabled: false,
          isLast: false,
        },
        {
          value: 'urlParameter',
          translationKey: 'urlParams',
          disabled: false,
          isLast: false,
        },
        {
          value: 'localStorage',
          translationKey: 'localStorage',
          disabled: true,
          isLast: true,
        },
      ];
    },
    allActions() {
      return [
        { text: this.$t('settings.page.context.allActions.showElement'), value: 'showElement', disabled: false },
        { text: this.$t('settings.page.context.allActions.hideElement'), value: 'hideElement', disabled: false },
      ];
    },
    personalizationSetData() {
      const personalizationSetData = [];
      this.personalizationValues.forEach((set) => {
        personalizationSetData.push({
          value: set.setId,
          text: set.name,
        });
      });
      personalizationSetData.sort((a, b) => a.text.localeCompare(b.text));
      return [
        { value: null, text: this.$t('settings.page.context.selectPersonalizationSet') },
        ...personalizationSetData,
      ];
    },
  },
  filters: {
    printName(value, personalizationObject) {
      // split value
      const [setId, valueString] = value.split('.');
      // find the valueString inside the personalizationObject
      const setObject = personalizationObject.find((set) => set.setId === Number(setId));
      if (setObject) {
        // find the name of the element
        const itemObject = setObject.values.find((item) => item.id === valueString);
        if (itemObject) return `${setObject.name}:${itemObject.name}`;
      }
      return value;
    },
    actionMapping(value, allActions) {
      const actionName = allActions.find((action) => action.value === value);
      return (actionName) ? actionName.text : value;
    },
  },
  methods: {
    createContext(newContext) {
      this.context.push({
        event: 'onFetchMagazine',
        source: newContext.source,
        action: newContext.action,
        cases: {
          or: [],
        },
      });
    },
    updateContext(newContext) {
      const contextIndex = this.context.findIndex(
        (context) => context.action === newContext.action && context.source === newContext.source,
      );
      if (contextIndex === -1) return;
      const { orIndex } = newContext;
      const operation = {};
      operation[newContext.operator] = [
        { var: newContext.leftValue },
        newContext.rightValue,
      ];
      if (orIndex === undefined) {
        const contextItem = {
          and: [],
        };
        contextItem.and.push(operation);
        // add the item to the end of the relevant context
        this.context[contextIndex].cases.or.push(contextItem);
      } else {
        // and context inside the or context
        this.context[contextIndex].cases.or[orIndex].and.push(operation);
      }
    },
    updateContextElement(updatedContext) {
      this.context = cloneDeep(updatedContext);
    },
    copyContext(newContext) {
      this.context = cloneDeep(newContext);
    },
    removeContext(contextIndex) {
      this.context.splice(contextIndex, 1);
    },
    removeElement(element) {
      this.context[element.action].cases.or[element.or].and.splice(element.and, 1);
      if (this.context[element.action].cases.or[element.or].and.length === 0) {
        this.context[element.action].cases.or.splice(element.or, 1);
        if (this.context[element.action].cases.or.length === 0) {
          this.context.splice(element.action, 1);
        }
      }
    },
    cancelModal() {
      this.$emit('cancel-modal');
    },
    saveContext() {
      // emit save event with the updated context
      this.$emit('editor-context', cloneDeep(this.context));
    },
    showItemModal(action, source, orIndex) {
      this.selectedOrIndex = orIndex;
      this.selectedAction = action;
      this.selectedSource = source;
      this.showContextItemModal = true;
    },
    finalizeUpdateElement(operation) {
      this.showContextItemModal = false;
      // Replace the entire object at the specified indices
      if (this.context[this.selectedActionIndex]
        && this.context[this.selectedActionIndex].cases.or[this.selectedOrIndex]
        && this.context[this.selectedActionIndex].cases.or[this.selectedOrIndex].and[this.selectedAndIndex]) {
        this.context[this.selectedActionIndex].cases.or[this.selectedOrIndex].and[this.selectedAndIndex] = operation;
      }
      // reset the selected values
      this.resetSelectedValues();
      this.updateContextElement(this.context);
    },
    cancelContextItemModal() {
      this.showContextItemModal = false;
      this.resetSelectedValues();
    },
    resetSelectedValues() {
      this.isElementUpdate = false;
      this.selectedLeftValue = null;
      this.selectedOperator = null;
      this.selectedRightValue = null;
      this.selectedOrIndex = null;
      this.selectedAndIndex = null;
      this.selectedAction = null;
      this.selectedSource = null;
      this.selectedActionIndex = null;
    },
    copySettings() {
      localStorage.setItem('context_settings', JSON.stringify(this.context));
      this.settingsCopied = true;
    },
    pasteSettings() {
      const newSettings = localStorage.getItem('context_settings');
      localStorage.removeItem('context_settings');
      this.settingsCopied = false;
      this.copyContext(JSON.parse(newSettings));
    },
  },
};
</script>

<style scoped lang="scss">
span {
  color: #0d0d0d;
}

/deep/ .modal-dialog {
  .modal-content {
    border: none;

    label {
      color: #6c757d;
    }
  }

  .custom-control-input ~ .custom-control-label {
    color: #6c757d;
  }

  .custom-control-input[disabled] ~ .custom-control-label {
    cursor: not-allowed;
    color: #bbb;
  }

  button:disabled {
    cursor: not-allowed;
  }
}

.and-wrapper {
  border: 1px solid $site-font-color;
}

.box {
  // background-color: #53575B;
}

/deep/ .text-white{
  color: #0d0d0d !important;
}
/deep/ .and-box{
  background-color: #eeeeee !important;
}
/deep/ .wrapper-box h3, /deep/ .new-value-box h3 {
  font-size: 21px;
  color: #44484c;
}

/deep/ div.new-value-box {
  background-color: #fff;
  border: 1px solid #bbb;

  p {
    color: #0d0d0d;
    font-size: 12px;
  }
}
/deep/ .show > .btn-secondary.dot-button.dropdown-toggle,
/deep/ .btn-secondary.dot-button.dropdown-toggle{
  color: #000;
}

/deep/ .dot-button {
  background-color: transparent;
  border: none;
}

.close {
  cursor: pointer;
}
/deep/ .header-section, /deep/ .description-section {
  display: none !important;
}
/deep/ .context-wrapper .context {
  padding-top: 0 !important;
}
/deep/ .dropdown .dropdown-toggle {
    right: 0;
    top: -3px;
}
</style>
